import { useGtm } from "@gtm-support/vue-gtm";
const { isIos } = useDevice();
const gtm = useGtm();

export const pushEventDataLayer = (
  eventName: string,
  value: string,
  location: string,
) => {
  const os = isIos ? "ios" : "android";
  setTimeout(function () {
    gtm?.push({
      [eventName]: `${value}_${location}_${os}`,
    });
  }, 500);
};
