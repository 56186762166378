<script lang="ts" setup>
import { routes } from "~/constants";

const isOpen = ref(false);

const closePopup = () => {
  isOpen.value = false;
};
</script>

<template>
  <DialogBox class="c-popup-user-check-type" :open="isOpen" @close="closePopup">
    <div class="row">
      <div class="col-xs-12 bloc candidat">
        <span class="bloc-title">{{
          $t("COMPONENTS.POPUP_CHECK_TYPE.CANDIDATES.@TITLE")
        }}</span>
        <ButtonLink
          type="candidat"
          :label="$t('COMPONENTS.POPUP_CHECK_TYPE.CANDIDATES.@LABEL')"
          picto="extra-logo"
          :url="routes.candidatUrl.index"
          :is-custom="true"
        />
      </div>
      <div class="col-xs-12">
        <TheSeparator :text="$t('CANDIDATS.SEPARATOR.@TEXT')" />
      </div>
      <div class="col-xs-12 bloc recruteur" @click="closePopup">
        <span class="bloc-title">{{
          $t("COMPONENTS.POPUP_CHECK_TYPE.ETAB.@TITLE")
        }}</span>
        <ButtonLink
          type="recruteur"
          :label="$t('COMPONENTS.POPUP_CHECK_TYPE.ETAB.@LABEL')"
          picto="extra-logo"
          :fake-cta="true"
        />
      </div>
    </div>
  </DialogBox>
</template>

<style lang="scss">
.c-dialog-box {
  &.c-popup-user-check-type {
    display: flex;
    max-width: 490px;
    background: var(--showcase-color-additional-light);
    border-radius: 12px;
    box-shadow: 0px 1px 20px 0px rgba(112, 112, 112, 0.15);
    width: 100%;
    .c-dialog-box__body {
      width: 100%;
      padding-right: 0;
      margin: 0;
      grid-column: 1;
    }
    .bloc {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &-title {
        display: flex;
        font-weight: 800;
        margin-bottom: var(--showcase-size-125);
        width: 100%;
        justify-content: center;
      }
      &.recruteur {
        .bloc-title {
          color: var(--showcase-color-secondary-200);
        }
        .btn-link.recruteur {
          &:hover {
            background-color: #fff;
            border-color: var(--showcase-color-secondary-200);
            i {
              &:before {
                color: var(--showcase-color-secondary-200);
              }
            }
            .btn-text {
              color: var(--showcase-color-secondary-200);
            }
          }
        }
      }
      &.candidat {
        .bloc-title {
          color: var(--showcase-color-primary-200);
        }
      }
      .btn-link .btn-text {
        font-size: 14px;
        @include mq(desktop) {
          font-size: 16px;
        }
      }
    }
  }
}

.popup-lead-confirmation-enter-active,
.popup-lead-confirmation-leave-active {
  transition:
    transform 200ms ease-in,
    opacity 250ms ease-in;
}

.popup-lead-confirmation-enter-from,
.popup-lead-confirmation-leave-to {
  transform: translate3d(0, 10px, 0);
  opacity: 0;
}
</style>
